<template>
  <OneColumn style="background: #19173B; overflow: auto;">
    <template v-slot:ColumnContent>
      <div class="level">
        <div class="container">
          <h2 class="header">{{ level.name }}</h2>
          <div class="desc">
            {{ level.description }}
          </div>
          <h6 class="tasks-header" @click="refreshPage">Задачи уровня</h6>

          <div class="tasks">
            <div class="tasks-item" v-for="task of tasks" :key="task.id">
              <div class="name-status">
                <div>
                  {{
                    task.test_id != null ? task.QuizShortData.name : task.name
                  }}
                </div>
                <div>
                  <template v-if="tasks[0].test_id == null">
                    <div v-if="user" class="status">
                      <div>
                        {{
                          history.findLast((elem) => elem.task_id === task.id)
                            ? history.findLast(
                                (elem) => elem.task_id === task.id
                              ).status.name
                            : "Не сдана на проверку"
                        }}
                      </div>
                      <p
                        v-if="
                          history.find((elem) => elem.task_id === task.id) ===
                            undefined
                        "
                      >
                        <i class="bx bxs-x-circle" style="color: #d74949"></i>
                      </p>
                      <p
                        v-else-if="
                          history.findLast((elem) => elem.task_id === task.id)
                            .status.name === 'Выполнена'
                        "
                      >
                        <i
                          class="bx bxs-check-circle"
                          style="color: #24db6e"
                        ></i>
                      </p>
                      <p
                        v-else-if="
                          history.findLast((elem) => elem.task_id === task.id)
                            .status.name === 'Не выполнена' ||
                            history.findLast((elem) => elem.task_id === task.id)
                              .status.name === 'Отклонена'
                        "
                      >
                        <i class="bx bxs-x-circle" style="color: #d74949"></i>
                      </p>
                      <p
                        v-else-if="
                          history.findLast((elem) => elem.task_id === task.id)
                            .status.name === 'На проверке'
                        "
                      >
                        <i class="bx bxs-hourglass" style="color: #395f76"></i>
                      </p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="status">
                      <div>
                        {{ getBestTextStatus.text }}
                      </div>
                      <p v-if="getBestTextStatus.id != 0">
                        <i
                          v-if="getBestTextStatus.id == 1"
                          class="bx bxs-check-circle"
                          style="color: #24db6e"
                        ></i>
                        <i
                          v-if="getBestTextStatus.id == 2"
                          class="bx bxs-x-circle"
                          style="color: #d74949"
                        ></i>
                      </p>
                    </div>
                  </template>
                </div>
              </div>
              <div class="second-colm">
                <div class="tasks-desc ql-snow">
                  <span
                    class="ql-editor"
                    v-html="
                      task.test_id != null
                        ? task.QuizShortData.description
                        : task.description
                    "
                  ></span>
                </div>

                <div class="tasks-desc ">
                  <template
                    v-if="task.test_id == null && task?.Materials?.length > 0"
                  >
                    <span class="head-desc">Материалы:</span>
                    <ul>
                      <li v-for="(material, k) in task.Materials" :key="k">
                        <a :href="material.url_file">{{
                          material.url_file.slice(
                            Math.max(
                              material.url_file.lastIndexOf("/"),
                              material.url_file.lastIndexOf("\\")
                            ) + 15
                          )
                        }}</a>
                      </li>
                    </ul>
                  </template>
                </div>

                <div>
                  <template v-if="task.test_id == null">
                    <ButtonGradientInsideElement
                      v-if="
                        !(
                          user &&
                          user.user_tasks.find(
                            (elem) => elem.task_id === task.id
                          ) &&
                          user.user_tasks.find(
                            (elem) => elem.task_id === task.id
                          ).status.name === 'Выполнена'
                        )
                      "
                      @click.native="openDialog(task.id)"
                      ButtonText="Загрузить решение"
                    />
                  </template>
                  <template v-else>
                    <router-link
                      :to="
                        `/beta/tests/${task.test_id}/task/${this.$route.params.level}/${task.id}`
                      "
                    >
                      <ButtonGradientInsideElement
                        :ButtonText="
                          getLatestAttemptStatus == `start`
                            ? `Начать тестирование`
                            : `Продолжить тестирование`
                        "
                      />
                    </router-link>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <UserTaskHistory
            :user="user.id"
            :level="this.$route.params.level"
            :key="
              tasks.length == 0
                ? false
                : tasks[0].test_id == null
                ? false
                : true
            "
            :isTestHistory="
              tasks.length == 0
                ? false
                : tasks[0].test_id == null
                ? false
                : true
            "
            :TestHistory="UserAttempts"
            :TestSuccessPercent="
              tasks.length == 0
                ? 100
                : tasks[0].test_id == null
                ? 100
                : tasks[0]?.QuizShortData?.success_limit
            "
          ></UserTaskHistory>

          <el-dialog
            v-model="dialogAddTask"
            title="Выполнить задачу"
            width="40%"
          >
            <div>
              <div class="demo-input-suffix">
                <el-row :gutter="20">
                  <span class="demo-input-label">Описание решения</span>
                  <!-- <el-input
                v-model="addTasksForm.description"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
                placeholder="Введите описание решения"
              >
              </el-input> -->
                  <div
                    class="QEditor"
                    style="position: relative; min-height: 300px; padding-bottom: 100px;"
                  >
                    <QuillEditor
                      ref="QuillEditor"
                      :modules="modules"
                      toolbar="full"
                      theme="snow"
                      contentType="html"
                      :content="addTasksForm.description"
                      @textChange="handleEditorEditChanges"
                      @ready="handleEditorEditReady"
                    />
                  </div>
                  <div class="loadForm">
                    <div class="tipFormLoad">
                      <strong style="color: #d64a4a">Обязательно</strong>
                      загрузите файл c ответом, иначе кнопка "Добавить решение"
                      будет неактивна.
                    </div>
                    <div>
                      <el-upload
                        class="upload-demo"
                        action="#"
                        :auto-upload="false"
                        :on-change="handleSuccess"
                        :limit="1"
                        :on-remove="handleRemove"
                      >
                        <el-button size="small" type="primary"
                          >Загрузить файл</el-button
                        >

                        <template #tip> </template>
                      </el-upload>
                    </div>
                  </div>
                </el-row>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="dialogAddTask = false">Отменить</el-button>
                <el-button
                  id="addTaskFormBtn"
                  :disabled="!addTasksForm.file || blockUpdateButton"
                  type="primary"
                  @click="addTask"
                  >Добавить решение</el-button
                >
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </template>
  </OneColumn>
</template>

<script>
import ButtonGradientInsideElement from "@/components/CustomElements/ButtonGradient";
import ButtonGradientOutsideElement from "@/components/CustomElements/ButtonGradientBorder";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import ImageCompress from "quill-image-compress";

import UserTaskHistory from "@/components/Edu/UserTaskHistory";

import { getAllTaskByLevel } from "@/api/task.js";
import { getLevel } from "@/api/competence.js";
import { createSolution, findByUserAndLevel } from "@/api/solution.js";
import { findById } from "@/api/user.js";

import { getQuizShortInfo, findUserAttempts } from "@/api/user_tests.js";

import { mapGetters, mapMutations } from "vuex";

import OneColumn from "@/components/ProfileTemplates/OneColumn.vue";

export default {
  name: "LevelPage",
  components: {
    UserTaskHistory,
    QuillEditor,
    OneColumn,
    ButtonGradientInsideElement,
    ButtonGradientOutsideElement,
  },
  setup: () => {
    const moduleImageCompress = {
      name: "imageCompress",
      module: ImageCompress,
      options: {
        quality: 0.65,
        maxWidth: 1920, // default
        maxHeight: 1920, // default
        imageType: "image/webp",
        keepImageTypes: ["image/jpeg", "image/png", "image/webp"],
        debug: false,
        insertIntoEditor: (imageBase64URL, imageBlob, editor) => {
          return "";
        },
      },
    };

    const modules = [moduleImageCompress];

    return { modules };
  },
  data() {
    return {
      level: {
        name: "",
        description: "",
      },
      tasks: [],
      dialogAddTask: false,
      addTasksForm: {
        task_id: null,
        description: "",
        file: null,
      },

      blockUpdateButton: false,
      UserAttempts: [],

      history: [],
    };
  },
  async created() {
    getLevel(this.$route.params.level).then((response) => {
      this.level = response.data;
    });

    findByUserAndLevel({
      user: this.user.id,
      level: this.$route.params.level,
    }).then((response) => {
      console.log(response);
      this.history = response.data;
    });

    let _level_tasks_resp = await getAllTaskByLevel(this.$route.params.level);
    let _level_tasks_array = _level_tasks_resp.data;
    for (let lvl of _level_tasks_array) {
      if (lvl.test_id != null) {
        let _quiz_data_resp = await getQuizShortInfo(lvl.test_id);
        lvl.QuizShortData = _quiz_data_resp.data;
      }
    }
    this.tasks = _level_tasks_array;

    if (_level_tasks_array[0].test_id != null) {
      let _attemps_resp = await findUserAttempts(
        _level_tasks_array[0].test_id,
        this.user.id
      );

      this.UserAttempts = _attemps_resp.data;
    }
  },

  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    getLatestAttemptStatus() {
      if (this.UserAttempts.length == 0) {
        return "start";
      }

      if (this.UserAttempts[this.UserAttempts.length - 1].fact_finish == null) {
        return "continue";
      }

      return "start";
    },

    getBestTextStatus() {
      if (this.UserAttempts.length == 0) {
        return {
          id: 0,
          text: "Ожидание тестирования",
        };
      }

      let bestScore = 0;
      for (let attempt of this.UserAttempts) {
        if (attempt.score > bestScore) {
          bestScore = attempt.score;
        }
      }

      if (this.tasks[0].test_id == null) {
        return null;
      }

      let testDone = this.tasks[0].QuizShortData.success_limit <= bestScore;

      return {
        id: testDone ? 1 : 2,
        text: testDone ? "Тест пройден" : "Тест не сдан",
      };
    },
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),
    openDialog(task_id) {
      this.addTasksForm = {
        task_id: task_id,
        description: this.user.user_tasks.find(
          (elem) => elem.task_id === task_id
        )
          ? this.user.user_tasks.find((elem) => elem.task_id === task_id)
              .description
          : "",
        file: null,
      };
      this.dialogAddTask = true;
    },
    handleRemove(file, fileList) {
      this.addTasksForm.file = null;
    },
    handleSuccess(file, fileList) {
      this.addTasksForm.file = {
        url: URL.createObjectURL(file.raw),
        file: file.raw,
        name: file.name,
      };
    },
    addTask() {
      if (this.blockUpdateButton) {
        return;
      }
      this.blockUpdateButton = true;

      const formData = new FormData();
      if (this.addTasksForm.file) {
        formData.append(
          "file",
          this.addTasksForm.file.file,
          this.addTasksForm.file.name
        );
      } else {
        formData.append("file", null);
      }
      formData.append("description", this.addTasksForm.description);
      formData.append("task_id", this.addTasksForm.task_id);
      formData.append("user_id", this.user.id);
      formData.append("status_id", 2);
      createSolution(formData)
        .then(() => {
          findById(this.user.id).then((response) => {
            this.SET_USER(response.data);
          });
          this.dialogAddTask = false;
          this.$message({
            type: "success",
            message: `Решение отправлено!`,
          });

          this.addTasksForm.description = "";
          this.addTasksForm.file = null;
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: `Произошла ошибка`,
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.blockUpdateButton = false;
          }, 2000);
        });
    },

    handleEditorEditChanges(delta) {
      this.addTasksForm.description = this.$refs.QuillEditor.getHTML();
    },
    handleEditorEditReady() {
      this.$refs.QuillEditor.setHTML(this.addTasksForm.description);
    },
  },
};
</script>

<style scoped>
@import "./../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.name-status .status > p {
  position: relative;
  display: flex;
  width: fit-content;
  height: fit-content;
  margin-bottom: 0;
}
.name-status .status {
  position: relative;
  display: flex;
}
.name-status .status > * {
  margin-top: auto;
  margin-bottom: auto;
}
.name-status .status > div {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.2rem !important;
  color: #e8e7ec !important;
}
.name-status > div {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  line-height: 1.2rem !important;
  color: #e8e7ec !important;
}
.name-status > * {
  margin-top: auto;
  margin-bottom: auto;
}

.loadForm {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
  flex-direction: column;
}
.tipFormLoad {
  margin-bottom: 10px;
  font-size: 13px;
  text-align: start;
  word-break: normal;
}
.el-button:disabled:hover {
  background-color: #409eff !important;
  opacity: 0.5 !important;
}
.el-message-box {
  background-color: #d64a4a !important;
  font-size: 2rem;
}

.upload-file {
  color: gray;
  font-size: 0.8rem;
}
.upload-btn {
  background-color: #d64a4a;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 0.8rem;
}
.head-desc {
  font-weight: 600;
}
div.second-colm {
  padding: 20px 40px;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  flex-direction: column;
}
div.second-colm div {
  margin-bottom: 15px;
}
.tasks-desc {
  position: relative;
  width: 100%;

  text-align: start;
  color: gray;
  font-size: 0.8rem;
}
.status {
  display: flex;
  font-weight: 400;
  font-size: 0.9rem;
}
.name-status {
  padding: 30px;
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;

  background: rgba(32, 30, 72, 0.7) !important;
  box-shadow: 8px 0 8px rgba(31, 18, 35, 0.15) !important;
  border-radius: var(--o-s-global-border-radius) !important;
  border: none;
}

i {
  margin-left: 10px;
  font-size: 1.4rem;
}

.tasks {
  width: 100%;
}

.tasks-item {
  background: rgba(32, 30, 72, 0.7) !important;
  box-shadow: 8px 0 8px rgba(31, 18, 35, 0.15) !important;
  border-radius: var(--o-s-global-border-radius) !important;
  border: none;

  margin-bottom: 30px;
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tasks-header {
  font-weight: 600;
  margin: 30px 0px;
  color: black;
}

.desc {
  text-align: start;
  font-weight: 500;
  display: flex !important;
  color: black;
}

.header {
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 600;
  color: black;
}

.container {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}

.level {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  min-height: 100vh;
}
</style>

<style scoped>
@media (max-width: 479px) {
  .level > .container {
    width: 100%;
  }

  .level > .container > .header {
    padding: 0% 3% 0% 3%;
  }
  .level > .container > .desc {
    text-align: justify;
    margin-bottom: 30px;
    padding: 0% 3% 0% 3%;
  }

  .level > .container > .tasks-header {
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
  }

  .level > .container > .tasks > .tasks-item > .name-status {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .level
    > .container
    > .tasks
    > .tasks-item
    > .name-status
    > *:nth-child(2)
    > .status
    > p
    > i {
    margin: auto 0% auto 10px !important;
  }

  .level
    > .container
    > .tasks
    > .tasks-item
    > .name-status
    > *:nth-child(2)
    > .status
    > p {
    position: relative;
    width: fit-content;
    margin: auto 0% auto 0%;
  }

  .level
    > .container
    > .tasks
    > .tasks-item
    > .name-status
    > *:nth-child(2)
    > .status
    > div {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: auto 0% auto 0%;
  }

  .level > .container > .tasks > .tasks-item > .second-colm > .tasks-desc {
    font-size: 0.9rem;
    text-align: justify;
    color: #dbdbdb;

    word-break: break-word;
  }

  .level > .container > .tasks > .tasks-item > .name-status > div:nth-child(1) {
    text-align: left;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .level > .container {
    width: 90%;
  }
  .level > .container > .desc {
    text-align: justify;
    margin-bottom: 30px;
  }

  .level > .container > .tasks-header {
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
  }

  .level > .container > .tasks > .tasks-item > .name-status {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .level
    > .container
    > .tasks
    > .tasks-item
    > .name-status
    > *:nth-child(2)
    > .status
    > p
    > i {
    margin: auto 0% auto 10px !important;
  }

  .level
    > .container
    > .tasks
    > .tasks-item
    > .name-status
    > *:nth-child(2)
    > .status
    > p {
    position: relative;
    width: fit-content;
    margin: auto 0% auto 0%;
  }

  .level
    > .container
    > .tasks
    > .tasks-item
    > .name-status
    > *:nth-child(2)
    > .status
    > div {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: auto 0% auto 0%;
  }

  .level > .container > .tasks > .tasks-item > .second-colm > .tasks-desc {
    font-size: 0.9rem;
    text-align: justify;
    color: #dbdbdb;

    word-break: break-word;
  }

  .level > .container > .tasks > .tasks-item > .name-status > div:nth-child(1) {
    text-align: left;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .level > .container {
    width: 90%;
  }
  .level > .container > .desc {
    text-align: justify;
    margin-bottom: 30px;
  }

  .level > .container > .tasks-header {
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
  }

  .level > .container > .tasks > .tasks-item > .name-status {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .level > .container > .tasks > .tasks-item > .second-colm > .tasks-desc {
    font-size: 0.9rem;
    color: #dbdbdb;
  }

  .level > .container > .tasks > .tasks-item > .name-status > div:nth-child(1) {
    text-align: left;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
  .level > .container > .desc {
    text-align: justify;
    margin-bottom: 30px;
  }

  .level > .container > .tasks-header {
    font-size: 1.4rem;
    text-align: left;
    width: 100%;
  }

  .level > .container > .tasks > .tasks-item > .name-status > div:nth-child(2) {
    flex-shrink: 0;
  }

  .level > .container > .tasks > .tasks-item > .second-colm > .tasks-desc {
    font-size: 0.9rem;
    color: #dbdbdb;
  }

  .level > .container > .tasks > .tasks-item > .name-status > div:nth-child(1) {
    text-align: left;
  }
}
@media (min-width: 1400px) {
  .level > .container > .desc {
    text-align: justify;
    margin-bottom: 30px;
  }

  .level > .container > .tasks-header {
    font-size: 1.4rem;
    text-align: left;
    width: 100%;
  }

  .level > .container > .tasks > .tasks-item > .name-status > div:nth-child(2) {
    flex-shrink: 0;
  }

  .level > .container > .tasks > .tasks-item > .second-colm > .tasks-desc {
    font-size: 0.9rem;
    color: #dbdbdb;
  }

  .level > .container > .tasks > .tasks-item > .name-status > div:nth-child(1) {
    text-align: left;
  }
}
</style>
